/* @font-face {
  font-family: "American Typewriter";
  src: local("American Typewriter"),
    url(./fonts/American-Typewriter-Regular.ttf) format("truetype");
} */
@font-face {
  font-family: "Classic Console";
  src: local("Classic Console"),
    url(./fonts/clacon.ttf) format("truetype");
}

body {
  margin: 0;
  /* font-family: American Typewriter; */
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
